
function ComplimiPrivacy() {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          alignItems: "center",
          backgroundColor: "#5756D5",
          color: "white"
        }}
      >
        <div
          style={{
            width: "50%",
            textAlign: "left",
            paddingLeft: "25%",
            paddingRight: "25%",
            paddingTop: "10%",
            paddingBottom: "10%",
            color: "white",
          }}
        >
          <p>PRIVACY POLICY</p>
          <p>Effective date: Dec 12 2022</p>
          Complimi is an app for getting daily compliments on your looks.
          <br></br><br></br>
          Below, we outline what data we collect and how we use it. 
          <br></br><br></br>
          1. INFORMATION WE COLLECT AND HOW WE COLLECT IT
          <br></br><br></br>
          We may collect several types of information, including but not limited to Personal Data, from and about you:
          <br></br>
          Directly from you when you provide it to us.
          Automatically when you Use Complimi. Information collected automatically may include Usage details and internet protocol (“IP”) addresses.
          <br></br>
          From third parties, for example, our Service Providers (as defined below).
          To the extent information is: (i) associated with an identified or identifiable natural person and (ii) protected as personal data under applicable data protection laws, such information is referred to in this Privacy Policy as “Personal Data”.e.
          <br></br><br></br>
          Contacts data
          <br></br><br></br>
          After signing up, Complimi asks for access to your address book. This
          makes it easy to add your friends to your Complimi. First and foremost, we
          never save your contacts and never send text messages to your friends
          without your explicit permission. Some apps abuse their access to your
          contacts, so we want to be sure your friends' data always stays in your
          hands, not ours.
          <br></br><br></br>
          When you add a friend on Complimi, we first look up their phone number in
          our records to see if they already have an account. If they don't, then
          we store their phone number without any personally identifiable
          information attached (no name, no profile picture, no nothing). Linking
          their number to your account means that once they sign up, they can
          immediately accept your friend request. We do not save the phone numbers
          of any users you don't explicitly add as a friend.
          <br></br><br></br>
          To invite a new user to the app, Complimi gives you the option of sending
          them a text message with a link to install the app. We do not
          automatically message your friends on your behalf. Instead, you have to
          send the message yourself. This means you're always in control of what
          messages get sent to your friends. Everyone hates when an app secretly
          sends messages to all their Contacts, us included.
          <br></br><br></br>
          Once you add a friend, Complimi looks up the name and profile picture you
          have saved for that person in your Contacts. This information is then
          used to identify your friend throughout the app. It's important to note
          that this data never leaves your phone and never gets saved to our
          databases.
          <br></br><br></br>
          Changes to this policy
          <br></br><br></br>
          Complimi can change this policy at any time. If this policy is changed,
          all users will receive a message in the app with the new policy.
          <br></br><br></br>
          Downloading or deleting your data
          <br></br><br></br>
          To download your data or deactivate your account, please email
          team@onlywidget.com and include your user id available in app settings
        </div>
      </div>
    );
  }
  
  export default ComplimiPrivacy;
  