
function ComplimiSupport() {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          backgroundColor: "#5756D5",
          color: "white"
        }}
      >
        <h3>For support or feedback on Complimi please reach out to us at: <a href="team@onlywidget.com">team@onlywidget.com</a></h3>
      </div>
    );
  }
  
  export default ComplimiSupport;
  