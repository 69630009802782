import { useEffect } from "react";
import { Link } from "react-router-dom";
//import TiktokPixel from 'tiktok-pixel';
//import ReactPixel from 'react-facebook-pixel';

function Home() {
  useEffect(() => {
    if (window.location.pathname !== "/home") {
      window.location.href = "https://apps.apple.com/us/app/complimi/id6446147076";
    }
    const options = {
      debug: false, // enable logs
    };
    
    //TiktokPixel.init('CEPGQ13C77UFTJ8GRJDG', options);
    
    //TiktokPixel.pageView();
    /*ReactPixel.init('1899182927083609', {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    });*/
    
    //ReactPixel.pageView();
  }, []);

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        justifyContent: "flex-start",
        backgroundColor: "#5756D5"
      }}
    >
      <h1 style={{textAlign: "left", paddingLeft: "4%", paddingTop: "12%", marginBottom: 20, color: "white"}}>Complimi</h1>
      <h2 style={{textAlign: "left", paddingLeft: "4%", width: "40%", marginBottom: 20, color: "white"}}>Get personalized compliments every day</h2>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://apps.apple.com/us/app/complimi/id6446147076"
      >
        <img
          src="/appstore_badge.svg"
          alt="Download on the App Store"
          style={{
            height: "auto",
            width: 180,
            marginTop: 10,
            cursor: "pointer",
            position: 'absolute',
            bottom: "10%",
            left: "4%",
          }}
        />
      </a>
      <div style={footerStyle}><Link style={{paddingRight: 20, textAlign: "left",}} to="/complimi/terms">Terms</Link><Link style={{paddingRight: 20, textAlign: "left",}} to="/complimi/privacy">Privacy</Link></div>
    </div>
  );
}

const footerStyle = {
  textAlign: "center",
  justifyContent: 'center',
  alignItems: 'center',
  padding: "20px",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "20px",
  width: "100%"
};

export default Home;
