import iphone from '../iphone.png';
import '../App.css';

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={iphone} style={{ height: '80vh'}} />
        <div style={{flexDirection: 'row', width: '100%', position: 'absolute', top: '18vh'}}>
        <a
          style={{padding: '3vh', color: 'transparent'}}
          href="https://www.tiktok.com/@albingrans/video/7153316291980496133"
          target="_blank"
          rel="noopener noreferrer"
        >
          {'jb'}
        </a>
        <a
         style={{padding: '3vh', color: 'transparent'}}
          href="https://apps.apple.com/gb/app/betweet/id6444735198"
          target="_blank"
          rel="noopener noreferrer"
        >
          {'h'}
        </a>
        <a
         style={{padding: '3vh', color: 'transparent'}}
          href="https://claimaffirmations.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {'h'}
        </a>
        <a
         style={{padding: '3vh', color: 'transparent'}}
          href="https://petpicai.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          {'jb'}
        </a>
        </div>
        <div style={{flexDirection: 'row', width: '100%', position: 'absolute', top: '28vh'}}>
        <a
          style={{padding: '3vh', color: 'transparent'}}
          href="https://apps.apple.com/us/app/only-widget/id1621293743"
          target="_blank"
          rel="noopener noreferrer"
        >
          {'jb'}
        </a>
        <a
         style={{padding: '3vh', color: 'transparent'}}
          href="https://apps.apple.com/us/app/fit-check-ootd/id6443991773"
          target="_blank"
          rel="noopener noreferrer"
        >
          {'h'}
        </a>
        <a
         style={{padding: '3vh', color: 'transparent'}}
          href="https://apps.apple.com/us/app/vibes-see-whos-vibing/id1660118341"
          target="_blank"
          rel="noopener noreferrer"
        >
          {'h'}
        </a>
        <a
         style={{padding: '3vh', color: 'transparent'}}
          href="https://apps.apple.com/us/app/takes-fm/id1584793062"
          target="_blank"
          rel="noopener noreferrer"
        >
          {'jb'}
        </a>
        </div>
        <div style={{flexDirection: 'row', width: '100%', position: 'absolute', top: '36vh', marginRight: '18vh'}}>
        <a
          style={{padding: '3vh', color: 'transparent'}}
          href="https://discord.gg/zYu7kwDTHU"
          target="_blank"
          rel="noopener noreferrer"
        >
          {'jb'}
        </a>
        <a
         style={{padding: '3vh', color: 'transparent'}}
          href="https://www.tiktok.com/@albingrans"
          target="_blank"
          rel="noopener noreferrer"
        >
          {'jb'}
        </a>
        </div>
        <div style={{flexDirection: 'row', width: '100%', position: 'absolute', top: '82vh', marginRight: '18vh'}}>
        <a
          style={{padding: '3vh', color: 'transparent'}}
          href=""
          target="_blank"
          rel="noopener noreferrer"
        >
          {'jb'}
        </a>
        <a
         style={{padding: '3vh', color: 'transparent'}}
          href="mailto:miles.scherrer@gmail.com?body=Dear sir, I have an inquiry regarding Jailbreak Ventures..."
          target="_blank"
          rel="noopener noreferrer"
        >
          {'jb'}
        </a>
        </div>
      </header>
    </div>
  );
}

export default Home;
