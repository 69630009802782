import iphone from './iphone.png';
import './App.css';
import {
  BrowserRouter,
  Routes, //replaces "Switch" used till v5
  Route,
} from "react-router-dom";


import Home from "./components/Home";
import ComplimiHome from "./components/ComplimiHome";
import ComplimiTerms from "./components/ComplimiTerms";
import ComplimiPrivacy from "./components/ComplimiPrivacy";
import ComplimiSupport from "./components/ComplimiSupport";

function App() {
  return (
  <div className="App">
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/complimi" element={<ComplimiHome />} />
        <Route path="/complimi/terms" element={<ComplimiTerms />} />
        <Route path="/complimi/privacy" element={<ComplimiPrivacy />} />
        <Route path="/complimi/support" element={<ComplimiSupport />} />
      </Routes>
    </BrowserRouter>
  </div>
  );
}

export default App;
